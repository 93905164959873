import { useEffect } from 'react';
import { getValidResponseBody, productApi } from '../api';
import { useFrontload } from 'react-frontload';

export const useRecommendedProducts = (products) => {
  const { data, setData } = useFrontload('useRecommendedProducts', async ({ productApi }) => {
    const recommendedProducts = products?.length ? await productApi.getMoreLikeThis(products) : [];
    return { recommendedProducts };
  });

  const recommendedProducts = getValidResponseBody(data?.recommendedProducts)?.hits?.hits.map(({ _source, _id }) => ({
    ..._source,
    id: _id,
  }));

  useEffect(() => {
    (async () => {
      if (!recommendedProducts?.length && !products?.length) return;

      const recommendedProducts = products?.length ? await productApi.getMoreLikeThis(products) : [];
      setData((data) => ({ ...data, recommendedProducts }));
    })();
  }, [...products]);

  return { recommendedProducts };
};
