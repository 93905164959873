import { getValidResponseBody, staticApi } from '../../api';
import { useSelector } from 'react-redux';
import sliderData from 'ShopConfig/homepage_sliders.json';
import { useEffect, useState } from 'react';

export const useHomePageData = () => {
  const { config } = useSelector(({ config }) => ({ config }));
  const [statsData, setStatsData] = useState(undefined);

  /**
   * Load the page data
   */
  const getStartsData = async () => {
    const statsData = await staticApi.getMarketStatus();
    setStatsData(statsData);
  };

  useEffect(() => {
    getStartsData();
  }, []);

  const stats = getValidResponseBody(statsData);

  const categorySlider = sliderData ? sliderData.find((slide) => slide.type === 'category') : [];
  const brandSlider = sliderData ? sliderData.find((slide) => slide.type === 'brand') : [];
  const clickoutSlider = sliderData ? sliderData.find((item) => item.type === 'clickout') : [];
  const metaTitle = config.head.title;
  const metaDescription = config.head.description;

  return {
    stats,
    categorySlider,
    brandSlider,
    clickoutSlider,
    metaTitle,
    metaDescription,
    baseUrl: config.baseUrl,
  };
};
