import React from 'react';
import loadable from '@loadable/component';
import loadVisibleComponent from 'Utils/loadVisibleComponent';
import { SwitchableFeature } from 'Features/SwitchableFeature/SwitchableFeature';
import {
  BLOG_GRID,
  BRANDS_SLIDER_SECTION,
  FEATURED_CATEGORIES_SECTION,
} from 'Features/SwitchableFeature/SwitchableFeature.definitions';
import { useHomePageData } from './useHomePageData';

import FullscreenWelcomeSection from 'Components/FullscreenWelcomeSection';
import HomePageMeta from '../../components/HomePageMeta';
import RecommendedByRVPSection from '../../components/RecommendedByRVPSection';
import RecommendedByWishlistSection from '../../components/RecommendedByWishlistSection';

const BlogGrid = loadable(() => import('Components/BlogGrid/BlogGrid'));
const NewsletterSection = loadable(() => import('Components/NewsletterSection/NewsletterSection'));
const Footer = loadable(() => import('Components/Footer/Footer'));

const CategorySliderSection = loadVisibleComponent(() => import('Components/CategorySliderSection'));
const BrandsSliderSection = loadVisibleComponent(() => import('Components/BrandsSliderSection'));
const FeaturedCategoriesSection = loadVisibleComponent(() =>
  import('Components/FeaturedCategoriesSection/FeaturedCategoriesSection')
);
const PageDescription = loadVisibleComponent(() => import('Components/PageDescription/PageDescription'));
const UspsSection = loadVisibleComponent(() => import('Components/UspsSection/UspsSection'));
const RecentlyViewedProducts = loadVisibleComponent(() =>
  import('Components/RecentlyViewedProducts/RecentlyViewedProducts')
);

const Home = () => {
  const { stats, categorySlider, brandSlider, metaTitle, metaDescription, baseUrl, clickoutSlider } = useHomePageData();

  return (
    <>
      <HomePageMeta metaTitle={metaTitle} metaDescription={metaDescription} baseUrl={baseUrl} />
      <FullscreenWelcomeSection stats={stats} clickoutSlider={clickoutSlider} />
      {/* Blog Grid */}
      <SwitchableFeature feature={BLOG_GRID}>
        <BlogGrid />
      </SwitchableFeature>
      {/* Category Sliders */}
      <CategorySliderSection categorySlider={categorySlider} />
      {/* Brands Slider */}
      <SwitchableFeature feature={BRANDS_SLIDER_SECTION}>
        <BrandsSliderSection brandSlider={brandSlider} />
      </SwitchableFeature>
      <RecommendedByRVPSection />
      <RecommendedByWishlistSection />
      {/* Featured categories @todo: This is super heavy HTML nodes-wise */}
      {/* This section alone brings us a couple of points down */}
      <SwitchableFeature feature={FEATURED_CATEGORIES_SECTION}>
        <FeaturedCategoriesSection productsCount={stats?.total} />
      </SwitchableFeature>
      <PageDescription />
      <UspsSection />
      <RecentlyViewedProducts />
      <NewsletterSection />
      <Footer />
    </>
  );
};

Home.displayName = 'Home';
export default Home;
